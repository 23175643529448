import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrap = styled.g`
	rect {
		fill: ${p => p.theme.colors.white};
		transition: opacity 0.35s;
	}
	text {
		fill: ${p => p.theme.colors.black};
		transition: opacity 0.35s;
	}
`;

/**
 * Renders a tooltip.
 * @param {Object} props - Component props.
 * @param {string} props.children - The content of the tooltip.
 * @param {boolean} props.show - Whether to show the tooltip.
 * @param {Object} props.position - The position of the tooltip.
 * @returns {JSX.Element} Rendered component.
 */
export default function Tooltip({ children, show, position }) {
	const [opacity, setOpacity] = useState(0);
	const textRef = useRef(null);

	useEffect(() => {
		if (show) {
			setOpacity(1);
		} else {
			setOpacity(0);
		}
	}, [show]);

	if (!show || !children) return null;

	// Get the width of the text
	const textWidth = textRef?.current?.getBBox()?.width || 100;

	return (
		<Wrap className="tooltip">
			<rect
				x={position?.x - textWidth / 2 - 10} // adjust these values to position the background correctly
				y={position?.y - 40}
				width={textWidth + 20} // adjust these values to change the size of the background
				height="30"
				rx="4"
				ry="4"
				style={{
					opacity: opacity,
				}}
				filter="url(#tooltip-shadow)" // apply the filter
			/>
			<text
				ref={textRef}
				x={position?.x}
				y={position?.y - 19}
				textAnchor="middle"
				style={{
					opacity: opacity,
				}}>
				{children}
			</text>

			{/* Shadow filter */}
			<defs>
				<filter
					id="tooltip-shadow"
					x="-50%"
					y="-50%"
					width="200%"
					height="200%">
					<feGaussianBlur
						in="SourceAlpha"
						stdDeviation="4"
						result="blur"
					/>
					<feFlood
						floodColor="#28292c"
						floodOpacity="0.3"
						result="color"
					/>
					<feComposite
						in="color"
						in2="blur"
						operator="in"
						result="shadow"
					/>
					<feOffset in="shadow" dx="1" dy="1" result="offsetBlur" />
					<feMerge>
						<feMergeNode in="offsetBlur" />
						<feMergeNode in="SourceGraphic" />
					</feMerge>
				</filter>
			</defs>
		</Wrap>
	);
}
