import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Tooltip from './Tooltip';
import Heading from 'libs/heading';
import { generateColorGradient } from 'parts/stats/Stats';

const ChartWrapper = styled.div`
	svg {
		max-width: 720px;
		height: auto;
		max-height: 720px;
	}
`;

const Text = styled.text`
	font-size: 20px;
	fill: ${p => p.theme.colors.black};
	font-weight: 500;

	${p =>
		p.theme.media.XSmallOnly(css`
			font-size: 22px;
		`)}
`;

const Guideline = styled.path`
	stroke: ${p =>
		p.color === 'Grønn' ? p.theme.colors.green300 : p.theme.colors.blue300};
	stroke-width: 1;
	stroke-miterlimit: 10;
	stroke-dasharray: 4;
`;

/**
 * Renders a chart, either a bar chart or a line chart.
 * @param {Object} props - Component props.
 * @param {string} props.variant - The type of chart ("bar-chart" or "line-chart").
 * @param {Array} props.stats - Array of objects representing statistics.
 * @param {number} props.min - The minimum value on the Y-axis.
 * @param {number} props.max - The maximum value on the Y-axis.
 * @param {number} props.steps - The number of steps on the Y-axis.
 * @param {number} props.start - Whether to start the animation.
 * @param {string} props.YAxis - The label for the Y-axis.
 * @param {string} props.XAxis - The label for the X-axis.
 * @param {string} props.description - The description for the chart.
 * @returns {JSX.Element} Rendered component.
 */
export default function Chart({ variant = 'line-chart', ...props }) {
	const [showValues, setShowValues] = useState(false);

	const {
		stats = [],
		min = 0,
		max = 1400,
		steps = 4,
		start,
		YAxis,
		XAxis,
		title,
		color,
	} = props;

	// Calculate the spacing between the bars
	const barSpacing = stats.length > 5 ? 20 : 40;

	// Calculate the offset for the bars
	const offset = stats.length > 5 ? 60 : 85;

	// Calculate the total available width for the bars (600 - left and right padding)
	const totalWidth = 720 - 2 * offset;

	// Calculate the width of each bar
	const barWidth =
		stats?.length > 1
			? (totalWidth - barSpacing * (stats.length - 1)) / stats.length
			: totalWidth;

	// Calculate the start position for the first bar
	const startX = offset * 1.5;

	// Description for the chart, if not provided, use the stats
	const description =
		props.description ||
		stats?.map(stat => `${stat.label}: ${stat.value} ${YAxis}`).join(', ');

	// Set a timeout to show the values after 1 second
	useEffect(() => {
		if (!start) return;
		setTimeout(() => {
			setShowValues(true);
		}, 1000);
	}, [start]);

	if (!variant || !stats?.length > 0) return null;

	return (
		<ChartWrapper>
			{title && (
				<Heading
					level={
						props?.settings?.includes('Skjul tittel og intro')
							? 'h2'
							: 'h3'
					}>
					{title}
				</Heading>
			)}
			<svg
				className={`graph graph__${variant}`}
				style={{ width: '100%', height: 'auto' }} // Make the SVG responsive
				fill="none"
				xmlns="https://www.w3.org/2000/svg"
				viewBox="0 0 720 720"
				aria-label={description}>
				{/* Horizontal Guidelines */}
				{steps > 0 && (
					<g className="guidelines guidelines__horizontal">
						{Array.from({ length: steps + 1 }).map((_, i) => {
							// Calculate the y position for each line
							const y = 600 - (i / steps) * 600;
							return (
								<Guideline
									key={i}
									color={color}
									d={`M 85 ${y + 35} H 720`}></Guideline>
							);
						})}
					</g>
				)}

				{/* Vertical Guidelines */}
				{stats?.length > 0 && (
					<g className="guidelines guidelines__vertical">
						{/* Start line */}
						<Guideline
							color={color}
							d={`M 85 35 V 632`}></Guideline>
						{stats?.map((_, i) => {
							// Calculate the x position for each line
							const x = startX + i * (barWidth + barSpacing);
							return (
								<Guideline
									key={i}
									color={color}
									d={`M ${
										x + barWidth / 2
									} 35 V 632`}></Guideline>
							);
						})}
						{/* End lines */}
						<Guideline
							color={color}
							d={`M 720 35 V 632`}></Guideline>
					</g>
				)}

				{/* Bars or line */}
				<ChartElement
					variant={variant}
					startX={startX}
					start={start}
					showValues={showValues}
					stats={stats}
					barWidth={barWidth}
					barSpacing={barSpacing}
					max={max}
					color={color}
				/>

				{/* x-axis */}
				<g className="xAxis">
					{stats?.map((stat, i) => (
						<Text
							key={i}
							x={
								startX +
								i * (barWidth + barSpacing) +
								barWidth / 2
							} // center the text below the rect
							y="675" // Move the text down to make room for the bars
							textAnchor="middle">
							{stat?.label}
						</Text>
					))}
					{/* Axis Title */}
					{XAxis && (
						<Text x="350" y="700" textAnchor="middle">
							{XAxis}
						</Text>
					)}
				</g>

				{/* y-axis */}
				<g className="yAxis">
					{steps > 0 &&
						Array.from({ length: steps + 1 }).map((_, i) => {
							const y = 600 - (i / steps) * 600; // Calculate the y position for each step

							return (
								<Text
									key={i}
									x="70"
									y={y + 40}
									textAnchor="end">
									{Math.round(
										min + (i / steps) * (max - min)
									)}
								</Text>
							);
						})}
					{YAxis && (
						<Text
							x="0"
							y="285"
							textAnchor="end"
							transform="rotate(-90 30,300)">
							{YAxis}
						</Text>
					)}
				</g>
			</svg>
		</ChartWrapper>
	);
}

/**
 * Renders either bars or a line in a chart.
 * @param {Object} props - Component props.
 * @param {string} props.variant - The type of chart ("bar-chart" or "line-chart").
 * @param {number} props.startX - The starting position on the X-axis.
 * @param {number} props.start - Whether to start the animation.
 * @param {boolean} props.showValues - Whether to show the values.
 * @param {Array} props.stats - Array of objects representing statistics.
 * @param {number} props.barWidth - The width of the bars.
 * @param {number} props.barSpacing - The spacing between the bars.
 * @param {number} props.max - The maximum value on the Y-axis.
 * @param {string} props.color - The color of the chart.
 * @returns {JSX.Element} Rendered component.
 */
const ChartElement = ({
	variant,
	startX,
	start,
	showValues,
	stats,
	barWidth,
	barSpacing,
	max,
	color,
}) => {
	if (variant === 'bar-chart') {
		return (
			<Bars
				startX={startX}
				start={start}
				showValues={showValues}
				stats={stats}
				barWidth={barWidth}
				barSpacing={barSpacing}
				max={max}
				color={color}
			/>
		);
	} else if (variant === 'line-chart') {
		return (
			<Line
				startX={startX}
				start={start}
				showValues={showValues}
				stats={stats}
				barWidth={barWidth}
				barSpacing={barSpacing}
				max={max}
				color={color}
			/>
		);
	} else {
		return null;
	}
};

const Value = styled.text`
	opacity: 0;
	font-weight: 600 !important;
	font-size: 20px;
	fill: ${p =>
		(p.type === 'white' && p.theme.colors.white) ||
		p.color ||
		p.theme.colors.black};

	${p =>
		p.theme.media.XSmallOnly(css`
			font-size: 22px;
		`)}

	&.animate {
		animation-delay: ${p =>
			(p.$valueIndex && `calc(${p.$valueIndex}* 0.2s)`) || '0s'};
		animation: fadeIn 1s ease-in-out forwards;
	}
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const Bar = styled.path`
	transform: scaleY(0);
	fill: ${p => p?.color || p.theme.colors.green600};
	transform-origin: 50% calc(100% - 85px); /* Adjust the transform origin */
	&.animate {
		animation-delay: ${p =>
			(p.$barIndex && `calc(${p.$barIndex}* 0.2s)`) || '0s'};
		animation: grow 1s ease-in-out forwards;
	}

	/* Animation */
	@keyframes grow {
		to {
			transform: scaleY(1);
		}
	}
`;

/**
 * Renders bars in a bar chart. Each bar represents a statistic.
 * @param {Object} props - Component props.
 * @param {number} props.start - Whether to start the animation.
 * @param {boolean} props.showValues - Whether to show the values.
 * @param {Array} props.stats - Array of objects representing statistics.
 * @param {number} props.barWidth - The width of the bars.
 * @param {number} props.barSpacing - The spacing between the bars.
 * @param {number} props.max - The maximum value on the Y-axis.
 * @returns {JSX.Element} Rendered component.
 */
function Bars({ start, showValues, ...props }) {
	const { stats, barWidth, barSpacing, max, startX } = props;

	// Generate gradient colors based on the number of stats
	const colors = generateColorGradient(props?.color, stats?.length);

	// If there are no stats, return null
	if (!stats?.length > 0) return null;

	return (
		<g className="bars">
			{stats?.map((stat, i) => {
				// Calculate the x position for each bar
				const x = startX + i * (barWidth + barSpacing);

				// Calculate the y position for each bar
				const y = 600 - (stat?.value / max) * 600 + 35;

				// Calculate the height of the bar
				const height = (stat?.value / max) * 600;

				// Define the path for the bar with rounded top corners
				const d = `M ${x},${y + height} 
				 v ${-height + 4} 
				 a 10,10 0 0 1 10,-10 
				 h ${barWidth - 8} 
				 a 10,10 0 0 1 10,10 
				 v ${height - 4} 
				 z`;

				return (
					<g key={i}>
						{stat?.label && stat?.value && (
							<title>{`${stat?.label}: ${stat?.value}`}</title>
						)}
						<Bar
							aria-label={stat?.name}
							d={d}
							color={colors[i]}
							className={`bar ${start ? 'animate' : ''}`}
							$barIndex={i}
						/>
						<Value
							x={x + barWidth / 2 + 5} // center the text above the rect
							y={600 - (stat?.value / max) * 600 + 20} // position the text above the rect
							textAnchor="middle"
							$valueIndex={i}
							color={colors[i]}
							className={`value ${showValues ? 'animate' : ''}`}>
							{stat?.value}
						</Value>
					</g>
				);
			})}
		</g>
	);
}

const LinePath = styled.path`
	stroke-width: 4;
	//stroke: transparent;
	stroke-linecap: round;
	stroke-linejoin: round;

	&.animate {
		//stroke: ${p => p.theme.colors.blue600};
		stroke-dasharray: ${p => p.initialStroke};
		stroke-dashoffset: ${p => p.initialStroke};
		animation: lineAnimation 2s ease-in-out forwards;
	}

	@keyframes lineAnimation {
		to {
			stroke-dashoffset: 0;
		}
	}
`;

const Circle = styled.circle`
	opacity: 0;
	pointer-events: all;
	fill: transparent;
	&.animate {
		fill: ${p =>
			p.color === 'Grønn'
				? p.theme.colors.green600
				: p.theme.colors.blue600};
		animation-delay: ${p =>
			(p.valueIndex && `calc(${p.valueIndex}* 0.2s)`) || '0s'};
		animation: fadeIn 1s ease-in-out forwards;
	}
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

/**
 * Renders a line in a line chart. Each point represents a statistic
 * @param {Object} props - Component props.
 * @param {number} props.start - Whether to start the animation.
 * @param {boolean} props.showValues - Whether to show the values.
 * @param {Array} props.stats - Array of objects representing statistics.
 * @param {number} props.barWidth - The width of the bars.
 * @param {number} props.barSpacing - The spacing between the bars.
 * @param {number} props.max - The maximum value on the Y-axis.
 * @returns {JSX.Element} Rendered component.
 **/
function Line({ start, showValues, ...props }) {
	const { stats, barWidth, barSpacing, max } = props;

	// State for the tooltip
	const [tooltip, setTooltip] = useState({
		show: false,
		x: 0,
		y: 0,
		text: '',
	});

	// Create a reference to the line path
	const lineRef = useRef(null);

	// Calculate the length of the line path
	const lineLength = lineRef.current?.getTotalLength();

	// Set the initial stroke-dasharray and stroke-dashoffset to the length of the path
	const initialStroke = lineLength;

	// If there are no stats, return null
	if (!stats?.length > 0) return null;

	// Calculate the total width of all bars
	const totalWidth = stats?.length * (barWidth + barSpacing);

	// Calculate the start position for the first bar (100 offset on the left side)
	const startX = (600 - totalWidth) / 2 + 100;

	// Calculate the points for the line
	const points = stats?.map((stat, i) => {
		const x = startX + i * (barWidth + barSpacing) + barWidth / 2;
		let y = 600 - (stat?.value / max) * 600 + 32;
		return { x, y };
	});

	// Generate the line path
	let linePath = `M ${points[0].x},${points[0].y}`;
	for (let i = 0; i < points.length - 1; i++) {
		const currPoint = points[i];
		const nextPoint = points[i + 1];
		const controlPoint1X = (currPoint.x + nextPoint.x) / 2;
		const controlPoint1Y = currPoint.y;
		const controlPoint2X = (currPoint.x + nextPoint.x) / 2;
		const controlPoint2Y = nextPoint.y;
		linePath += ` C ${controlPoint1X},${controlPoint1Y} ${controlPoint2X},${controlPoint2Y} ${nextPoint.x},${nextPoint.y}`;
	}

	// NTE Grønn 500 or NTE Blå 500
	const lightColor = props?.color === 'Grønn' ? '#7DC189' : '#17A4D8';
	// NTE Grønn 800 or NTE Blå 800
	const darkColor = props?.color === 'Grønn' ? '#295334' : '#094462';

	return (
		<Fragment>
			<defs>
				{/* Shadow filter */}
				<filter
					id="shadow"
					x="-20%"
					y="-20%"
					width="140%"
					height="140%">
					<feGaussianBlur in="SourceAlpha" stdDeviation="2" />
					<feOffset dx="2" dy="2" result="offsetblur" />
					<feComponentTransfer>
						<feFuncA type="linear" slope="0.1" />
					</feComponentTransfer>
					<feMerge>
						<feMergeNode />
						<feMergeNode in="SourceGraphic" />
					</feMerge>
				</filter>

				{/* Gradient */}
				<linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
					<stop
						offset="0%"
						style={{ stopColor: darkColor, stopOpacity: 1 }}
					/>
					<stop
						offset="100%"
						style={{ stopColor: lightColor, stopOpacity: 1 }}
					/>
				</linearGradient>
			</defs>

			{/* Line */}
			<g className="line">
				<LinePath
					ref={lineRef}
					d={linePath}
					initialStroke={initialStroke}
					fill="none"
					stroke="url(#gradient)"
					className={`line ${start ? 'animate' : ''}`}
				/>
				{/* Values */}
				{stats?.map((stat, i) => {
					// Calculate the x position for each value
					const x =
						startX + i * (barWidth + barSpacing) + barWidth / 2;

					// Calculate the y position for each value
					const y = 600 - (stat?.value / max) * 600 + 32;

					return (
						<Fragment key={i}>
							<Circle
								valueIndex={i}
								aria-label={`${stat?.label}: ${stat?.value}`}
								cx={x}
								cy={y}
								color={props?.color}
								r="7" // radius of the circle
								className={`value ${
									showValues ? 'animate' : ''
								}`}
								onMouseEnter={() => {
									setTooltip({
										show: true,
										x,
										y,
										text: `${stat?.label}: ${stat?.value}`,
									});
								}}
								onMouseLeave={() =>
									setTooltip({
										show: false,
										x: 0,
										y: 0,
										text: '',
									})
								}
							/>
						</Fragment>
					);
				})}
			</g>
			{/* Tooltip */}
			{tooltip?.show && (
				<Tooltip show={tooltip?.show} position={tooltip}>
					{tooltip.text}
				</Tooltip>
			)}
		</Fragment>
	);
}
